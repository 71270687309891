@media (max-width: 1100px) {
    .footer-links {
      text-align: center;
    }
    
    .footer-space {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
  }  