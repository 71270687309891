.search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #ddd;
}

.search-bar .ant-select-dropdown {
    width: 100% !important;
}

.search-bar .ant-select-dropdown-menu {
    width: 100% !important;
  }

.location-wrapper {
    position: relative;
    flex: 1;
}

/* .location {
    width: 100%;
    padding-right: 30px;
}
 */
 
.location-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.25);
}

.search-bar .location {
    border-right: 2px solid rgb(245, 245, 245);
    border-radius: 0;
    height: 40px;
    width: 60vw;
    max-width: 800px;
}

.search-bar .datepicker {
    height: 40px;
    width: 30vw;
    max-width: 230px;
}

.search-bar .search-button {
    display: none;
    width: calc(100vw - 80px);
    font-weight: 400;
    max-width: 400px;
    margin-top: 2px;
}

@media screen and (max-width: 600px) {
    .search-bar {
        flex-direction: column;
        padding: 10px 20px;
        border-radius: 6px;
    }
    .search-bar .location {
        border-bottom: 2px solid rgb(245, 245, 245);
        border-right: 0px;
        width: calc(100vw - 80px);
        max-width: 400px;
    }
    .search-bar .datepicker {
        width: calc(100vw - 80px);
        max-width: 400px;
    }
    .search-bar .search-button-round {
        display: none;
    }
    .search-bar .search-button {
        display: block;
    }
}

.search-element {
    margin: 2px auto;
    font-size: 14px;
}

.search-element {
    margin: 2px auto;
    font-size: 14px;
}

.search-element.datepicker {
    height: 40px;
    flex: 1;
}

.search-element.search-button-round {
    margin-left: 8px;
}

/* NavSearchBarLite specific styles */

.nav-search-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    padding: 0 8px;
    border: 1px solid #ddd;
    width: 100%;
}

.nav-search-bar .location {
    border-right: 2px solid rgb(245, 245, 245);
    border-radius: 0;
    height: 40px;
    flex: 1;
}

.nav-search-bar .datepicker {
    height: 40px;
    flex: 1;
}

.nav-search-bar .search-button-round {
    margin-left: 8px;
}

.nav-location-wrapper {
    position: relative;
    flex: 1;
}

.nav-location {
    width: 100%;
    padding-right: 30px; /* Space for the icon */
}

.nav-location-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.25);
}