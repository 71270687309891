.navbar {
    overflow: hidden;
    background-color: #ffff;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    padding: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.hotel-navbar {
    padding: 20px 20px;
    display: flex;
    align-items: center;
}


.navbar-container {
    padding: 10px 10px; /* Added padding to allow spacing */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures space between elements */
    width: 100%;
}

.navbar .logobox {
    display: flex;
    align-items: center;
    flex: 1;
}

.navbar-logo {
    /*height: 60px;*/
    width: 200px; /* Adjust the width */
    height: auto;
    transition: transform 0.1s ease; /* Smooth transition for hover effect */
}

.navbar-logo:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
}

.search-bar-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar-container .nav-search-bar {
    max-width: 500px;
    width: 100%;
}

.button-group {
    display: flex;
    align-items: center;
}

.hotel-navbar .button-group-sm {
    padding: 0px 20px; 
}

/* Responsive design */
@media screen and (max-width: 748px) {
    /* .navbar .button-group {
        display: none;
    } */

    /*     @media screen and (min-width: 601px) {
        .navbar .button-group-sm {
            display: none;
        }
    } */

    /* Reduce the size of AutoComplete items in mobile view */
    .nav-location-wrapper .nav-location {
        font-size: 10px;
        padding: 4px;
    }

    .button-group-sm {
        display: flex; 
    }

    .navbar-logo {
        width: 100px; /* Smaller width for mobile devices */
        padding: 0px 10px;
    }

    .nav-location-wrapper .nav-location-icon {
        font-size: 10px;
        right: 4px;
    }

}