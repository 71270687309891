.day-use-card-col {
    display: flex;
    justify-content: center;
}

.day-use-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.day-use-card img {
    object-fit: cover;
    height: 200px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.day-use-card .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: var(--secondary-text-color);
    text-align: center;
}

.day-use-card .title {
    color: var(--primary-text-color);
    margin: 6px 0;
}

/* index.css */
.steps-card-col {
    display: flex;
    justify-content: center;
}

.steps-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    text-decoration: none;
    color: inherit;
    padding: 20px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.steps-card .icon {
    margin-bottom: 16px;
    color: var(--primary-text-color);
}

.steps-card .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text-color);
    text-align: center;
}

.steps-card .title {
    color: var(--primary-text-color);
    margin: 6px 0;
}

.widget-block {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.widget-heading {
    text-align: center;
    margin-bottom: 20px;
}

.widget-content {
    display: flex;
    flex-wrap: wrap;
}

.day-use-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    cursor: pointer;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .day-use-card-col {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .day-use-card-col {
        flex: 1 1 100%;
    }
}

.steps-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    cursor: pointer;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .steps-card-col {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .steps-card-col {
        flex: 1 1 100%;
    }
}

