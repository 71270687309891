@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura.woff2') format('woff2'),
       url('/fonts/Futura.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  --primary-color: #1a9c85;
  --primary-text-color: #161e2b;
  --secondary-text-color: #51676C;
  --secondary-color: #FAA612;
  --secondary-background-color: #d0e7e5;
  font-family: 'Futura', serif;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}