@font-face {
    font-family: 'Futura';
    src: url('/fonts/Futura.woff2') format('woff2'),
         url('/fonts/Futura.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  

.ios-status-bar:before {
    content: "";
    position: fixed;
    z-index: 10000;
    top: -100px;
    height: 100px;
    right: 0;
    left: 0;
    background-color: #fff;
}

html, body, #root {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
  
body {
    display: flex;
    flex-direction: column;
    font-family: 'Futura', serif;
}
  
#root {
    flex: 1;
}

.container {
    padding: 20px 10vw;
}

@media screen and (max-width: 1440px) {
    .container {
        padding: 0 4vw;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 0 5vw;
    }
}

/* antd */
.ant-picker-input input::placeholder {
    color: var(--secondary-text-color) !important;
    font-family: 'Futura', serif;
}

.ant-picker-input .ant-picker-suffix span {
    color: var(--primary-color);
}

.ant-input-affix-wrapper input::placeholder {
    color: var(--secondary-text-color) !important;
}

.ant-input-affix-wrapper .ant-input-suffix span {
    color: var(--primary-color);
}

.button-group, .button-group-sm {
    padding-right: 12px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0; /* Add padding to the buttons */
}

.right {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    padding-top: 16px;
}

@media screen and (max-width: 600px) {
    .right {
        justify-content: center;
        margin-right: 0px;
    }
}

.widget-block {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.widget-heading {
    text-align: center;
    margin-bottom: 10px;
}

.widget-content {
    display: flex;
    flex-wrap: wrap;
}

.title {
    font-family: 'Futura', serif;
    font-weight: 400;
    color: var(--primary-text-color);
}

.title-home {
    text-align: center;
    font-family: 'Futura', serif;
    font-weight: 400;
    color: var(--primary-text-color);
}

.custom-icon .tags div {
    border: var(--secondary-text-color) 1px solid;
    border-radius: 12px;
    margin: 2px;
    padding: 4px 8px;
    color: var(--secondary-text-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-icon .tags div:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-icon .tags div svg {
    color: var(--secondary-color);
    padding-right: 4px;
    font-size: 16px;
}

.custom-icon {
    font-family: 'Futura', serif;
    margin-top: 10px;
    padding-bottom: 10px;
}

.custom-icon .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    cursor: pointer;
    text-align: center;
}

.custom-icon-size {
    font-size: 16px;
}

.break-title {
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
  
.nowrap {
    white-space: nowrap;
}

.vertical-checkbox-group {
    display: flex;
    flex-direction: column;
}

.scrollable-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.ant-layout-footer {
    text-align: center;
    font-family: 'Futura', serif;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.filter-button {
    width: 100%;
    text-align: left;
}

.filter-button {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
@media (max-width: 768px) {
    .filter-button {
    display: inline-block;
    margin-right: 16px;
}
    
.filter-button:last-child {
      margin-right: 0;
    }
}
  
.mobile-filters {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
  
.mobile-filters button {
    flex: 1;
    margin: 0 5px;
    text-align: center;
}
  
.mobile-filters button:first-child {
    margin-left: 0;
}
  
.mobile-filters button:last-child {
    margin-right: 0;
}
  
@media (max-width: 600px) {
    .mobile-filters {
      justify-content: center;
}
  
.mobile-filters button {
      flex: 0 1 auto;
      margin: 0 10px;
    }
}
  
@media (max-width: 576px) {
    .break-title {
        flex-direction: column;
    }
}
  
@media (max-width: 768px) {
    .custom-icon-size {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .custom-icon-size {
        font-size: 12px;
    }
}

@media (max-width: 1030px) {
    .desktop-filters {
      display: none;
    }
  
    .mobile-filters {
      display: block;
    }
  }
  
  @media (min-width: 1031px) {
    .desktop-filters {
      display: block;
    }
  
    .mobile-filters {
      display: none;
    }
}

.page {
    margin-top: 80px;
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 15px; 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; 
    margin: 20px 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}
  
.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
  
.video-wrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;
    transform: scale(1.02);
}

.categories {
    padding-top: 20px;
    padding-bottom: 5px;
    font-size: 23px;
}

.page .after-cover-home {
    background-color: rgb(250, 250, 250);
}

.page .viwe-button {
    background-color: rgb(250, 250, 250);
}
