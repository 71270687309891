.hotel-details {
    padding-top: 20px;
}

.hotel-details .description {
    text-align: justify;
    font-size: 16px;
    font-family: 'Futura', serif;
    color: var(--secondary-text-color);
}

.hotel-details .second-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;
}

.hotel-details .title {
    color: var(--primary-text-color);
    display: inline-block;
    margin: 0;
}

.hotel-details .copy-button {
    vertical-align: middle;
    margin-left: 1px;
}

.hotel-details .location {
    color: var(--primary-color);
    padding-right: 4px;
}

.hotel-details .rating {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
}

.rating .rate {
    margin-left: 8px;
    font-size: 14px;
    color: var(--secondary-text-color);
}

.hotel-details .rate {
    padding-bottom: 2px;
    padding-left: 4px;
}

.hotel-details .tags {
    padding-bottom: 16px;
}

.hotel-details .tag {
    border: #51676C 1px solid;
    border-radius: 20px;
    margin-left: 4px;
    margin-bottom: 4px;
    padding: 4px 8px;
    color: #51676C;
    display: flex;
    align-items: center;
}

.hotel-details .tag svg {
    font-size: 16px;
    padding-right: 6px;
}

.hotel-details .tag-icon {
    display: flex;
    align-items: center
}

.hotel-details .tag-icon img {
    width: 32px;
    height: 32px;
    margin-left: 4px;
}

.hotel-details .social-icon {
    width: 30px !important;
    height: 30px !important;
    margin-left: 4px;
    margin-bottom: 4px;
}

/* Hotel package */

.hotel-package {
    border: 1px solid #51676C;
    border-radius: 8px;
    margin: 16px 0;
    overflow: hidden;
}

.hotel-package .col {
    padding: 16px;
    width: 100%;
    position: relative;
}

.hotel-package .title {
    background-color: var(--secondary-background-color);
    border-radius: 8px 0 0 8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    position: relative;
}

.hotel-package .title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    background: rgba(255, 255, 255, 0.8);
    z-index: 0; 
}

.hotel-package .title .first-row {
    position: relative;
    z-index: 2;
}

.hotel-package .description {
    color: #3d3d3d; 
    z-index: 3;
    position: relative;
}

/* Define styles for each category */

.hotel-package.dayout_with_room .title{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fdayout_w_room.webp?alt=media&token=86136f0d-1a37-4d05-967a-70d0cc6ecc61');
}

.hotel-package.overnight .title{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fdayout_w_room.webp?alt=media&token=86136f0d-1a37-4d05-967a-70d0cc6ecc61');
}

.hotel-package.dayout .title{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fdayout.webp?alt=media&token=af2307ba-2f94-48e5-b476-c9c822a9f58d');
}

.hotel-package.high_tea .title {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fhigh_tea.webp?alt=media&token=23bc7f0a-0294-4c25-85f4-bc97ba6659df');
}

.hotel-package.lunch .title {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Flunch.webp?alt=media&token=3d07e559-4ddd-40e2-a5a6-1016b91da796');
}

.hotel-package.dinner .title {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/zeylon-life-dev0.appspot.com/o/home%2Fdinner.webp?alt=media&token=84c3e8ab-dc12-402d-8307-50a7e27440dc');
}

.hotel-package .price {
    background-color: #e7f9f0;
}

.hotel-package .time {
    display: flex;
    align-items: center;
}

.hotel-package .time svg {
    color: var(--secondary-color);
    font-size: 24px;
    padding-right: 6px;
}

.hotel-package .first-row h3 {
    margin: 0;
}

.hotel-package .first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: 16px;
}

.hotel-package .people {
    display: flex;
    align-items: center;
}

.hotel-package .people svg {
    color: var(--primary-color);
    font-size: 24px;
    padding-right: 2px;
}

.hotel-package .amenity {
    margin: 6px 0;
    color: #51676C;
}

.hotel-package .amenity svg {
    color: var(--secondary-color);
    font-size: 16px;
    padding-right: 6px;
}

.hotel-package .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #51676C;
}

.hotel-package .price-old {
    text-align: center;
    text-decoration: line-through;
    color: var(--primary-text-color);
}

.hotel-package .price-new {
    text-align: center;
    color: var(--primary-color);
    font-size: 24px;
}

.hotel-package .price-caption {
    text-align: center;
    color: var(--secondary-text-color);
    font-size: 10px;
    margin-top: 5px;
}

.before-price {
    text-align: center;
    color: var(--secondary-text-color);
    font-size: 10px;
    margin-bottom: 5px;
}

.package-disclaimer {
    font-family: 'Futura', serif;
    color: var(--secondary-text-color);
}

@media screen and (max-width: 767px) {
    .hotel-package .title {
        border-radius: 8px 8px 0 0;
    }
    .hotel-package .price {
        border-top: 1px solid #51676C;
        border-left: none;
    }
}

.hotel-details .amenities .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.hotel-details .amenities{
    font-family: 'Futura', serif;
    font-size: 14px;
}

.hotel-details .amenities .tags div {
    border: #51676C 1px solid;
    border-radius: 12px;
    margin: 2px;
    padding: 4px 8px;
    color: #51676C;
}

.hotel-details .amenities .tags div svg {
    color: var(--secondary-color);
    padding-right: 4px;
    font-size: 18px;
}

.amenities {
    margin-top: 20px;
}

.amenities .tags {
    margin-top: 10px;
}

.reserve-button-container {
    margin-top: 10px;
    text-align: center;
}
.reserve-button {
    width: 100%;
}

.hotel-details .dayout-options .tags div svg {
    color: var(--secondary-color);
    padding-right: 4px;
    font-size: 18px;
}

.dayout-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dayout-options .tags {
    /* margin-top: 1px; */
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 1px; */
}

.dayout-options .tags div {
    border: #51676C 1px solid;
    border-radius: 12px;
    margin: 2px;
    padding: 4px 8px;
    color: #51676C;
}

/* Show dayout options on mobile view */
@media screen and (max-width: 600px) {
    .dayout-options {
        justify-content: center;
        align-items: center;
    }
    
    .dayout-options .option {
        margin: 2px;
    }
}

/* Carousel */

.carousel-root img {
    border-radius: 8px;
}

.carousel-root .carousel ul.thumbs {
    padding: 0;
    /* justify-content: center;
    display: flex; */
}

.carousel-root .carousel ul.thumbs img {
    border-radius: 0px !important;
}

.reservation-container {
    background-color: #f9f9f9;
    padding: 20px;
}

.reservation-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
}

.reservation-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.reservation-details {
    margin-bottom: 10px;
}

.reservation-subtitle {
    font-size: 16px;
    color: #888;
    margin-bottom: 0;
}

.confirm-button {
    width: 100%;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.confirm-button:hover {
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
}

/* Form fieldset styles */
form fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

form fieldset legend {
    display: none;
}

form fieldset:disabled input,
form fieldset:disabled .ant-select-selector {
    background-color: #fff !important;
    opacity: 1;
    color: #000;
    cursor: not-allowed;
}